import { Link, useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import logo from '../images/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';

function Header() {
  const { site } = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
          subtitle
          twitterLink
          facebookLink
        }
      }
    }
  `);

  return (
    <header className='py-16 bg-gray-100'>
      <div className='container mx-auto flex flex-wrap items-center justify-center'>
        <div className='flex flex-col items-center'>
          <Link
            className='mb-3 flex no-underline text-white w-1/2 md:w-1/3'
            to='/'
          >
            <img src={logo} alt={site.siteMetadata.title} />
          </Link>
          <p className='px-5 text-center font-semibold font-sans text-xs sm:text-base text-black'>
            {site.siteMetadata.subtitle}
          </p>
          <ul className='mt-5 pt-5 px-5 border-t-2 border-gray-300 flex flex-row'>
            {/* <li>
              <Link
                to='/'
                activeClassName='border-gray-500'
                className='px-4 py-2 border-transparent border-b-2 hover:border-primary-blue-700 hover:bg-primary-blue-100 focus:border-primary-blue-700 focus:bg-primary-blue-100'
              >
                Test Link
              </Link>
            </li> */}
            <li>
              <a
                className='px-4 py-2 border-transparent border-b-2 hover:border-primary-blue-700 hover:bg-primary-blue-100 focus:border-primary-blue-700 focus:bg-primary-blue-100'
                href={site.siteMetadata.twitterLink}
                aria-label='Twitter'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesomeIcon color='#1DA1F3' icon={faTwitter} />
              </a>
            </li>
            <li>
              <a
                className='px-4 py-2 border-transparent border-b-2 hover:border-primary-blue-700 hover:bg-primary-blue-100 focus:border-primary-blue-700 focus:bg-primary-blue-100'
                href={site.siteMetadata.facebookLink}
                aria-label='Facebook'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesomeIcon color='#3b5998' icon={faFacebook} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* Generates the two-tone background effect */}
      <div aria-hidden='true' className='relative text-gray-100'>
        {/* Covers up the space left by shifting down the polygon with margin */}
        <div className='absolute top-0 w-full bg-gray-100 h-24' />
        <svg
          className='absolute top-0 w-full h-48 mt-24'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 100 100'
          preserveAspectRatio='none'
        >
          <polygon className='fill-current' points='0,100 0,0 100,0' />
        </svg>
      </div>
    </header>
  );
}

export default Header;
