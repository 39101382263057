import PropTypes from 'prop-types';
import React from 'react';

import Header from './header';
import Contact from './contact';

function Layout({ children }) {
  return (
    <div className='flex flex-col min-h-screen text-gray-900 bg-primary-blue-700'>
      <Header />
      <main className='container mx-auto flex-1 z-20'>{children}</main>
      <Contact />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
