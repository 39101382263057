import React from 'react';

function Contact() {
  return (
    <form
      name='contact'
      method='post'
      className='mx-auto my-10 flex flex-col w-full sm:w-400 p-5 bg-white sm:rounded-md shadow-2xl'
      data-netlify='true'
      data-netlify-honeypot='misc-field'
    >
      <div className='text-lg font-sans font-semibold text-center mb-3'>
        Contact
      </div>
      <input type='hidden' name='form-name' value='contact' />
      <label className='hidden'>
        <span>Solve if you are an aspiring human:</span>
        <code>1 + 2 = </code>
        <input className='border-gray-300 rounded mt-1' type='number' name='misc-field' />
      </label>
      <label className='mb-3'>
        <span className='text-gray-700 font-bold'>Name</span>
        <input
          className='border-gray-300 rounded mt-1 block w-full'
          type='text'
          name='name'
          placeholder='Brant Larson'
          required
        />
      </label>
      <label className='mb-3'>
        <span className='text-gray-700 font-bold'>Email</span>
        <input
          className='border-gray-300 rounded mt-1 block w-full'
          type='email'
          name='email'
          placeholder='brant.larson@example.com'
          required
        />
      </label>
      <label className='mb-3'>
        <span className='text-gray-700 font-bold'>Message</span>
        <textarea
          className='border-gray-300 rounded mt-1 block w-full'
          name='message'
          placeholder='Hiya...'
          required
        ></textarea>
      </label>
      <button
        type='submit'
        className='bg-primary-blue-600 mx-auto mt-3 p-3 rounded-lg text-white'
      >
        Send
      </button>
    </form>
  );
}

export default Contact;
